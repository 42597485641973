import React, { useState, useEffect } from 'react';
import { Form, message } from 'antd';
import Calendar from '@sbmdkl/nepali-datepicker-reactjs';
import '@sbmdkl/nepali-datepicker-reactjs/dist/index.css';
import { adToBs, bsToAd } from '@sbmdkl/nepali-date-converter';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useAnonymous } from "../context/anonymousContext";
import dayjs from 'dayjs';

const NepaliDatePicker = (props) => {
  const { value, onChange, options, label, formContext } = props;
  const { enforceAgeRestriction, name } = options;
  const [date, setDate] = useState(value || '');
  const [error, setError] = useState('');
  const { status } = useAnonymous();

  useEffect(() => {
    if (value) {
      setDate(value);
      setError(''); // Clear any existing error when value changes
    }
  }, [value]);

  // Get today's date in BS
  const getTodayBS = () => {
    try {
      return adToBs(dayjs().format('YYYY-MM-DD'));
    } catch (error) {
      console.error("Error converting today's date:", error);
      return '';
    }
  };

  // Get 18 years ago in BS
  const getEighteenYearsAgoBS = () => {
    try {
      const eighteenYearsAgo = dayjs().subtract(18, 'years').format('YYYY-MM-DD');
      return adToBs(eighteenYearsAgo);
    } catch (error) {
      console.error("Error converting 18 years ago date:", error);
      return '';
    }
  };

  const handleDateChange = ({ bsDate, adDate }) => {
    setDate(bsDate);

    if (enforceAgeRestriction && formContext?.formData?.salutation !== "MINOR") {
      const age = dayjs().diff(dayjs(adDate), "years");
      if (age < 18) {
        // setError("You must be at least 18 years old.");
        // message.error("You must be at least 18 years old.");
        return;
      }
    }

    setError('');
    onChange(bsDate);
    options.onDateChange && options.onDateChange(bsDate);
  };

  const handleClearDate = () => {
    setDate('');
    setError('');
    onChange('');
  };

  const getMaxDate = () => {
    return enforceAgeRestriction ? getEighteenYearsAgoBS() : getTodayBS();
  };

  return (
    <Form.Item validateStatus={error ? "error" : ""} help={error}>
      <div style={{ position: 'relative', width: '100%' }}>
        <Calendar
          onChange={handleDateChange}
          defaultDate={date || getTodayBS()}
          value={date}
          theme="light"
          placeholder={`Select ${label}`}
          style={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid #d9d9d9",
            padding: "4px 11px",
            height: "43.6px"
          }}
          language="en"
          maxDate={getMaxDate()} // Restrict future dates
          disabled={status === 'submitted'}
          className={error ? 'error-input' : ''}
        />
        {date && (
          <CloseCircleOutlined
            style={{
              position: 'absolute',
              right: 10,
              top: '50%',
              transform: 'translateY(-50%)',
              fontSize: '16px',
              cursor: 'pointer',
              color: 'rgba(0, 0, 0, 0.45)',
            }}
            onClick={handleClearDate}
          />
        )}
      </div>
    </Form.Item>
  );
};

export default NepaliDatePicker;
