import React, { useState, useEffect, useRef } from "react";
import Form from "@rjsf/antd";
import validator, { customizeValidator } from "@rjsf/validator-ajv8";
import { transformErrors } from "../constants/transformErrors";
import CustomSelectWidget from "../widgets/CustomSelectWidget";
import ajvErrors from "ajv-errors";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDatePicker from "../widgets/CustomDatePicker";
import CustomFileUpload from "../widgets/CustomFileUpload";
import NepaliDatePicker from "../widgets/NepaliDatePicker";
import MapWidget from "../widgets/MapWidget";
import useFetchToken from "../hooks/useFetchToken";
import { Button, Col, message, Row, Spin } from "antd";
import { useAnonymous } from "../context/anonymousContext";
import {
  FileUploadProvider,
  useFileUpload,
} from "../context/FileUploadContext";
import useFetchFormData from "../hooks/useFetchFormData";
import { adToBs, bsToAd } from "@sbmdkl/nepali-date-converter";
// import useCreateDraft from '../hooks/useCreateDraft';
import { useDraft } from "../context/DraftContext";
import { useToken } from "../context/TokenContext";
import moment from "moment";
import CustomObjectFieldTemplate from "../shared/CustomObjectFieldTemplate";
import ObjectFieldTemplate from "../shared/CustomObjectFieldTemplate";
import CaptchaWidget from "../widgets/CaptchaWidget";
import ProgressBar from "../widgets/ProgressBar";
import ArrayWidget from "../widgets/ArrayWidget";
import TableWidgets from "../widgets/TableWidgets";
import CustomMultiSelectWidget from "../widgets/CustomMultiSelectWidget";

export const dynamicFormValidator = customizeValidator();
ajvErrors(dynamicFormValidator.ajv);
const DynamicFormGenerator = () => {
  const {
    anonymous,
    steps,
    setAnonymous,
    setSubmissionId,
    submissionId: contextSubmissionId,
    status,
    isAccountValidation,
    setStatus,
    setIsAccountValidation,
    setBrandingScript,
    brandingScript,
    currentStep,
    setCurrentStep,
    count,
    setCount,
  } = useAnonymous();
  const [skipValidation, setSkipValidation] = useState(false);
  const [serverErrors, setServerErrors] = useState({});
  const { id: paramsId, stepslug, slug } = useParams();
  const { token, loading } = useToken();
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [jsonSchema, setJsonSchema] = useState(null);
  const [uiSchema, setUiSchema] = useState(null);
  const previousFormData = useRef({});
  const location = useLocation();
  const { state } = location;
  const customerData = state?.customerData;
  const [errorState, setErrorState] = useState({});

  const [pendingSubmit, setPendingSubmit] = useState(false);
  const { loading: tokenLoading, error: tokenError } = useFetchToken();

  const { fileNames } = useFileUpload();
  const [globalErrorMessage, setGlobalErrorMessage] = useState("");
  const [fetchedData, setFetchedData] = useState({})
  const [optionsData, setOptionsData] = useState({})
  console.log(optionsData, "front");
  const { createDraft, isCreatingDraft } = useDraft();

  const masterDataUrl = `${process.env.REACT_APP_BASE_URL}/master-data`;
  const formKey = formData;


  const eachStepDataUrl = `${process.env.REACT_APP_BASE_URL}/forms/submissions/${stepslug}/${paramsId}`
  const allDataUrl = `${process.env.REACT_APP_BASE_URL}/forms/submissions/${paramsId}`;

  useEffect(() => {
    const initializeFactory = async () => {
      if (tokenLoading) return;

      try {
        // Fetch JSON schema
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/forms/${slug}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) throw new Error("Failed to fetch form schema");

        const data = await response.json();
        setAnonymous(data.data.anonymous === 1);

        if (data.data.branding_script) {
          setBrandingScript(data.data.branding_script);
        }

        const stepData = data.data.steps[currentStep];
        const jsonSchemaData = JSON.parse(stepData.form_schema);

        // Execute UI schema script
        if (stepData.ui_schema) {
          const executeScript = new Function(stepData.ui_schema);
          executeScript();
        }

        // Initialize UI Schema Factory
        if (window.UISchemaFactory) {
          const factory = new window.UISchemaFactory(masterDataUrl, {
            token,
            moment,
            anonymous,
            adToBs,
            bsToAd,
            status,
            paramsId,
            setCount,
            count,
            formData,
            setFetchedData,
            fetchedData,
            stepslug,
            allDataUrl,
            eachStepDataUrl,
            paramsId,
            optionsData,
            setOptionsData

          });

          const uiSchemaData = factory.createUISchema({
            formData: {},
            setFormData,
            jsonSchema: jsonSchemaData,
            widgets: {
              CustomSelectWidget,
              TableWidgets,
              ArrayWidget,
              MapWidget,
              CustomDatePicker,
              CustomFileUpload,
              NepaliDatePicker,
              CaptchaWidget,
              CustomMultiSelectWidget,
            },
            setJsonSchema,
            ObjectFieldTemplate,
          });

          setJsonSchema(jsonSchemaData);
          setUiSchema(uiSchemaData);
        } else {
          console.error("UISchemaFactory is not available");
        }
      } catch (error) {
        console.error("Error initializing factory:", error);
      }
    };

    initializeFactory();
  }, [token, tokenLoading, stepslug, slug, currentStep]);


  useEffect(() => {
    setIsAccountValidation(fetchedData?.include_account_validation === "Yes");
  }, [fetchedData, setIsAccountValidation]);

  useEffect(() => {
    if (fetchedData?.is_anonymous === 0) {
      const isVerified = sessionStorage.getItem("verified");

      if (isVerified) {
        navigate(`/services/${slug}/${stepslug}/${paramsId}`);
      } else {
        navigate(
          `/services/${slug}/${stepslug}/otp-form/${fetchedData?.request_id}`
        );
        message.success("Check your Email for OTP");
      }
    }
  }, [fetchedData, slug, stepslug, paramsId, navigate]);






  useEffect(() => {
    if (!jsonSchema || !uiSchema) return; // Wait for factory initialization

    // Determine initial form data
    const initialFormData = fetchedData?.form_data?.formData || {
      accountNumber: customerData?.accountNumber || "",
      mobileNumber: customerData?.mobileNumber || "",
      emailAddress: customerData?.emailAddress || "",
    };

    setFormData(initialFormData);

    if (stepslug === "review") {
      setStatus("submitted");
    }
  }, [jsonSchema, uiSchema, fetchedData, customerData, stepslug]);


  const onChange = ({ formData }) => {
    setFormData(formData);
    const watchedFields = jsonSchema?.watchField
    const fieldsChanged = watchedFields?.some(
      (field) =>
        formData[field] !== previousFormData.current[field]
    );

    if (fieldsChanged || formData) {

      const factory = new window.UISchemaFactory(masterDataUrl, {
        token,
        moment,
        anonymous,
        adToBs,
        bsToAd,
        status,
        paramsId,
        allDataUrl,
        setCount,
        count,
        jsonSchema,
        formData,
        setJsonSchema,
        setFormData,
        paramsId

      });

      // Re-create the UISchema with updated form data
      const uiSchemaData = factory.createUISchema({
        formData,
        setFormData,
        jsonSchema,
        widgets: {
          CustomSelectWidget,
          TableWidgets,
          ArrayWidget,
          MapWidget,
          CustomDatePicker,
          CustomFileUpload,
          NepaliDatePicker,
          CaptchaWidget,
          CustomMultiSelectWidget,
        },
        setJsonSchema,
        jsonSchema,
        ObjectFieldTemplate,
      });
      setUiSchema(uiSchemaData);
    }

    // Update errors
    const updatedErrors = { ...serverErrors };
    Object.keys(serverErrors).forEach((field) => {
      if (
        formData[field] &&
        formData[field] !== previousFormData.current[field]
      ) {
        delete updatedErrors[field];
      }
    });
    setServerErrors(updatedErrors);

    // Update previous form data
    previousFormData.current = formData;
  };


  const handleFormSubmission = async (formData, submissionType) => {
    const { ppSizePhoto, citizenship } = fileNames;
    const endpoint = `${process.env.REACT_APP_BASE_URL}/forms/submit`;
    const sessionNumber = sessionStorage.getItem("session_number");
    // Check if submissionId is available from params, context, or customerData
    let submissionId =
      paramsId || contextSubmissionId || customerData?.request_id;

    // If submissionId is not available, and the user is submitting, create a draft first
    if (!submissionId) {
      try {
        submissionId = await createDraft(slug, stepslug, token);
        if (!submissionId) {
          message.error("Failed to create draft. Please try again.");
          return;
        }
        setSubmissionId(submissionId); // Save the submissionId in the context
      } catch (error) {
        console.error("Error creating draft:", error);
        message.error("Failed to create draft. Please try again.");
        return;
      }
    }

    try {
      // Proceed with form submission
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          form_slug: slug,
          step_slug: stepslug,
          request_id: submissionId,
          submission_type: submissionType,
          sessionNumber,
          customerData,
          form_data: {
            formData
          },
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        handleServerErrors(result);
      } else {
        // Handle navigation based on submission type
        if (submissionType === "draft") {
          // For draft, navigate to the success page
          sessionStorage.setItem("currentStep", 0); // Reset step to 0
          navigateToSuccessPage(submissionId, "draft");
        } else if (submissionType === "submitted") {
          // For submit, navigate to the next step if available
          if (result.next_step) {
            setCurrentStep((prevStep) => prevStep + 1);
            navigate(`/services/${slug}/${result.next_step}/${submissionId}`, {
              state: { id: result.uuid, customerData }, // Pass the uuid if needed
            });
          } else {
            // If no next step, navigate to the success page
            sessionStorage.setItem("currentStep", 0);
            navigateToSuccessPage(submissionId, "submitted");
          }
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("Submission failed. Please try again.");
    }
  };



  const onSubmit = async ({ formData }) => {
    await handleFormSubmission(formData, "submitted");
  };


  const customValidate = (formData, errors) => {
    if (skipValidation) {
      return {};
    }

    Object.entries(serverErrors).forEach(([field, fieldErrors]) => {
      if (Array.isArray(fieldErrors)) {
        fieldErrors.forEach((item, index) => {
          if (errors[field] && errors[field][index]) {
            Object.entries(item).forEach(([subField, error]) => {
              if (errors[field][index][subField]) {
                errors[field][index][subField].addError(error);
              }
            });
          }
        });
      } else if (typeof fieldErrors === "object" && fieldErrors !== null) {
        if (
          Object.values(fieldErrors).every((val) => typeof val === "object")
        ) {
          Object.entries(fieldErrors).forEach(([index, indexErrors]) => {
            if (errors[field] && errors[field][index]) {
              Object.entries(indexErrors).forEach(([subField, error]) => {
                if (errors[field][index][subField]) {
                  errors[field][index][subField].addError(error);
                }
              });
            }
          });
        } else {
          if (errors[field]) {
            errors[field].addError(fieldErrors);
          }
        }
      } else {
        if (errors[field]) {
          errors[field].addError(fieldErrors.toString());
        }
      }
    });

    return errors;
  };

  const handleSubmit = async (submissionType) => {
    // Skip validation only if saving as draft
    if (submissionType === "draft") {
      setSkipValidation(true); // Skip validation for draft
    } else {
      setSkipValidation(false); // Ensure validation is done for submissions
    }

    // Trigger form submission manually for non-drafts
    if (submissionType === "submitted") {
      const form = document.querySelector("form");
      if (form) {
        form.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }
    } else {
      // Directly save as draft
      await handleFormSubmission(formData, "draft");
    }
  };

  const handleServerErrors = (errorData) => {
    if (Array.isArray(errorData) && errorData.length > 0) {
      errorData = errorData[0];
    }

    if (errorData.globalErrorMessage) {
      message.error(errorData.globalErrorMessage);
      setGlobalErrorMessage(errorData.globalErrorMessage);
    }

    if (errorData.fieldLevelErrors) {
      const newErrors = {};
      Object.entries(errorData.fieldLevelErrors).forEach(([field, errors]) => {
        if (Array.isArray(errors)) {
          newErrors[field] = errors.map((item) =>
            Object.entries(item).reduce((acc, [subField, subErrors]) => {
              acc[subField] = Object.values(subErrors).join(", ");
              return acc;
            }, {})
          );
        } else if (typeof errors === "object" && errors !== null) {
          if (Object.values(errors).every((val) => typeof val === "object")) {
            newErrors[field] = Object.entries(errors).reduce(
              (acc, [index, indexErrors]) => {
                acc[index] = Object.entries(indexErrors).reduce(
                  (subAcc, [subField, subErrors]) => {
                    subAcc[subField] = Object.values(subErrors).join(", ");
                    return subAcc;
                  },
                  {}
                );
                return acc;
              },
              {}
            );
          } else {
            newErrors[field] = Object.values(errors).join(", ");
          }
        } else {
          newErrors[field] = errors.toString();
        }
      });
      setServerErrors(newErrors);
    }
    if (errorData.error) {
      message.error(errorData.error);
      return;
    }
  };

  const navigateToSuccessPage = (id, submissionType) => {
    const draftUrl = `https://bpm-form.server247.info/services/${slug}/${stepslug}/${id}`;
    navigate(`/services/${slug}/${stepslug}/success-page`, {
      state: { id: id, draftUrl, submissionType },
    });
  };

  if (!jsonSchema || !uiSchema) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }
        }
      >
        <Spin size="large" spinning={true} />
      </div>
    );
  }

  return (
    <FileUploadProvider>
      <ProgressBar />
      < div className="container" >
        <Form
          key={`step-${currentStep}-${formKey}`}
          ObjectFieldTemplate={ObjectFieldTemplate}
          schema={jsonSchema}
          uiSchema={uiSchema}
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          customValidate={customValidate}
          validator={dynamicFormValidator}
          transformErrors={(errors) => {
            const transformedErrors = transformErrors(errors, jsonSchema);
            setErrorState(transformedErrors); // Optional: If you want to store errors
            return errors; // To keep standard errors for JSON Schema Form
          }}
          noHtml5Validate
          showErrorList={false}
          widgets={{
            CustomSelectWidget,
            CustomDatePicker,
            CustomFileUpload,
            NepaliDatePicker,
            MapWidget,
            CaptchaWidget,
            ArrayWidget,
            TableWidgets,
            CustomMultiSelectWidget
          }}
          formContext={{
            formData,
            errors: errorState, // Use state or directly pass transformed errors
            serverErrors,
          }}
        >
          <div style={{ marginTop: "16px" }}>
            <Row justify="space-between" >
              <Col>
                <Button
                  type="default"
                  onClick={() => handleSubmit("draft")}
                // disabled={status === 'submitted'}
                >
                  Save as Draft
                </Button>
              </Col>
              < Col >
                <Button
                  type="primary"
                  htmlType="submit"
                // onClick={() => handleSubmit("submitted")}
                // disabled={status === 'submitted'}
                >
                  Save and Continue
                </Button>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </FileUploadProvider>
  );
};

export default DynamicFormGenerator;
