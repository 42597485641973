import React, { useState, useRef, useCallback, useMemo } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useAnonymous } from "../context/anonymousContext";
const MapWidget = (props) => {
  const { value, onChange, options = {} } = props;
  const { searchkeyword = '' } = options;
  const {

    count,
    setCount,
  } = useAnonymous()
  const [markerPosition, setMarkerPosition] = useState(null);
  const mapRef = useRef(null);
  const geocoderRef = useRef(null);

  const mapContainerStyle = { width: '100%', height: '300px' };

  // Memoized default position
  const defaultPosition = useMemo(() => ({
    lat: 27.7090319,
    lng: 85.2911133,
  }), []);

  // Debounce function to prevent multiple rapid calls
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Geocode location with debounce
  const geocodeLocation = useCallback(
    debounce((keyword) => {
      if (!keyword || !geocoderRef.current) return;

      geocoderRef.current.geocode(
        { address: `${keyword}, Kathmandu, Nepal` },
        (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK && results[0]) {
            const location = results[0].geometry.location;
            const newPosition = {
              lat: location.lat(),
              lng: location.lng(),
            };

            // Update marker and map
            setMarkerPosition(newPosition);

            // Trigger onChange if provided
            if (onChange) {
              onChange(JSON.stringify(newPosition));
            }

            // Pan and zoom
            if (mapRef.current) {
              mapRef.current.panTo(newPosition);
              mapRef.current.setZoom(17);
            }
          } else {
            console.error('Geocoding failed:', status);
            // Fallback to default position
            setMarkerPosition(defaultPosition);
          }
        }
      );
    }, 800), // Increased delay to 800ms
    [onChange, defaultPosition]
  );

  // Initialize geocoder on map load
  const onLoad = useCallback((map) => {
    mapRef.current = map;
    geocoderRef.current = new window.google.maps.Geocoder();

    // Initial geocoding if searchkeyword exists
    if (searchkeyword) {
      geocodeLocation(searchkeyword);
    }
  }, [searchkeyword, geocodeLocation]);

  // Handle marker drag end
  const onMarkerDragEnd = useCallback((event) => {
    const newPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setMarkerPosition(newPosition);

    // Trigger onChange if provided
    if (onChange) {
      onChange(JSON.stringify(newPosition));
    }
  }, [onChange]);

  return (
    <div >
      <LoadScript
        googleMapsApiKey="AIzaSyBxKlsFHI1KbCj3Z9rFysr6FJQXSSgy--w"
        libraries={['places']}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={markerPosition || defaultPosition}
          zoom={markerPosition ? 17 : 12}
          onLoad={onLoad}
        >
          {markerPosition && (
            <Marker
              position={markerPosition}
              title={searchkeyword}
              animation={window.google.maps.Animation.DROP}
              draggable // Enable marker dragging
              onDragEnd={onMarkerDragEnd} // Capture new position after drag
            />
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default MapWidget;
